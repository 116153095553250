<template>
  <div class="page" :style="{ 'min-height': windowHeight }">
    <div class="vipInfo">
      <img class="vipName" src="../../assets/9.png" alt />
      <div class="vipText">
        <p class="vipNickName">{{ goodsDetail.name }}</p>
        <p
          class="vipNum"
          v-if="goodsDetail.flag == 0 && goodsDetail.expirationTime != null"
        >
          {{ appCode == 'taxlaw' ? '服务' : '会员' }}有效期：{{
            goodsDetail.expirationTime.substring(0, 10)
          }}
        </p>
        <p
          class="vipNum"
          v-else-if="goodsDetail.flag == 1 && goodsDetail.validNumber != null"
        >
          会员剩余次数：{{ goodsDetail.validNumber }}
        </p>
        <p class="vipNum" v-else>
          {{ appCode == 'taxlaw' ? '您还没有开通服务' : '您还不是会员' }}
        </p>
      </div>
      <p v-if="goodsDetail.goodsSpecificationList == ''" class="unitPrice">
        {{ goodsDetail.amount / 100 }}元/{{ goodsDetail.number
        }}{{
          goodsDetail.unit == 1
            ? '次'
            : goodsDetail.unit == 0
            ? '年'
            : goodsDetail.unit == 2
            ? '天'
            : ''
        }}
      </p>
      <p class="other" @click="toOrder">购买记录</p>
    </div>
    <!-- 套餐列表 -->
    <div class="memberPackage">
      <ul v-if="goodsDetail.goodsSpecificationList != ''">
        <!-- isVip:1-是，0-已过期，-1 新用户.type:  0-普通 1-仅新用户试用-->
        <li
          v-for="item in goodsDetail.goodsSpecificationList"
          v-show="!(goodsDetail.isVip != -1 && item.type == 1)"
          v-bind:key="item.id"
          @click="selectSize(item)"
          :class="goodSize == item.id ? 'new' : ''"
        >
          <div class="info">
            <p class="sizetype">{{ item.name }}</p>
            <p class="price">{{ item.amount / 100 }}元</p>
            <p class="text">
              服务期限<span v-if="item.size >= 36500">永久</span
              ><span v-else>
                {{ item.size
                }}{{
                  item.unit == 1
                    ? '次'
                    : item.unit == 0
                    ? '年'
                    : item.unit == 2
                    ? '天'
                    : ''
                }}</span
              >
            </p>
          </div>
        </li>
      </ul>
      <div class="agreement">
        <van-checkbox
          v-model="radio"
          style="display: inline-block; float: left"
          icon-size="12px"
        ></van-checkbox>
        <div
          style="display: inline-block; line-height: 21px; font-size: 12px"
          @click="agreeChange"
        >
          {{ detail.agreementName }}
        </div>
      </div>
      <div class="pay" @click="toBay">
        {{ appCode == 'taxlaw' ? '开通服务' : '开通会员' }}
      </div>
    </div>
    <div class="content">
      <div class="describe" v-html="goodsDetail.describe"></div>
    </div>

    <!--  -->
    <div class="dialog">
      <div v-show="isShow" class="tips">请仔细阅读并确认服务协议</div>
      <div v-show="sizeShow" class="tips">请选择会员套餐</div>
      <div v-show="buyShow" class="tips">会员套餐购买成功</div>
    </div>
    <!-- <div class="bottom">
      合计：
      <span class="amount"
        >{{ 
          goodsDetail.goodsSpecificationList == ""
            ? goodsDetail.amount / 100
            : amount / 100
        }}元</span
      >
      <div class="activation" @click="toActivation">激活码激活</div>
    </div> -->
    <van-overlay :show="showAgree" @click="showAgree = false">
      <div class="wrapper" @click="showAgree = false">
        <div class="block">
          <div v-html="detail.agreementName" class="dialogTop"></div>
          <div
            v-html="detail.agreementContent"
            style="
              text-indent: 25px;
              overflow-y: auto;
              height: 370px;
              text-align: justify;
              overflow: auto;
              padding: 0 5px 0 5px;
            "
          ></div>
          <div class="offSumbit" @click="showAgree = false">关闭</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block1">
          <div class="dialogTop">账号绑定</div>
          <div class="dialogPhone">
            <img
              src="../../assets/icon-6.png"
              style="float: left; margin-right: 10px; width: 20px"
            />
            <van-field
              v-model="phone"
              placeholder="手机号"
              type="number"
            ></van-field>
          </div>
          <div class="dialogCode">
            <img
              src="../../assets/icon-7.png"
              style="float: left; margin-right: 10px; width: 20px"
            />
            <van-field
              v-model="code"
              placeholder="验证码"
              type="number"
            ></van-field>
            <span
              v-show="sendAuthCode"
              style="font-size: 9px; color: #378bf4"
              @click="getCode"
              >获取验证码</span
            >
            <span v-show="!sendAuthCode" style="font-size: 9px; color: #777777"
              >已发送({{ auth_time }})</span
            >
          </div>
          <div class="dialogSumbit" @click="dialogSumbit">确定</div>
        </div>
      </div>
    </van-overlay>
    <!-- <div
      class="backButton"
      v-if="redirectUrl"
      :style="{top:y+'px',left:x+'px'}"
      @touchmove="moveing"
      @click.stop="backButton"
    >
      <img v-if="redirectUrl" src="../../assets/returnlogo.png" @click.stop="openButton" />
    </div> -->
  </div>
</template>
<script>
import {
  goodsDetail, //商品详情
  detail, //应用应用
  getUserInfo, //用户信息
  buy,
  // pay,
  sendCode,
  bindMoibleByCode,
} from '@/api/member'

import { Toast } from 'vant'
// const shopUrl = process.env.NODE_ENV === 'development' ? 'https://zsdy-mall-frontend-daily.shui12366.com' : 'https://zsdy-mall-frontend.shui12366.com';
let vm = null
export default {
  name: 'goods',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'divport',
        content:
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
      },
    ],
  },
  data() {
    return {
      lb: true,
      aaa: 0,
      orderId: '', //订单id
      appId: '',
      appCode: '',
      goodsCode: '',
      radio: true,
      detail: {},
      goodsDetail: [],
      userInfo: {},
      isShow: false,
      show: false,
      showAgree: false,
      sizeShow: false,
      buyShow: false,
      phone: '',
      code: '',
      amount: 0, //商品价格
      goodSize: '', //选择的商品型号
      status: '', //订单状态
      sendAuthCode: true, //验证码状态
      auth_time: 0,
      windowHeight: document.documentElement.clientHeight + 'px',
      maxX: document.documentElement.clientWidth - 50,
      maxY: document.documentElement.clientHeight - 80,
      redirectUrl: '',
      minX: 0,
      minY: 0,
      x: 100,
      y: 100,
      isOpen: false,
      successGoBack: '',
    }
  },
  created: function() {
    this.x = this.maxX
    this.y = this.maxY - 50
    this.redirectUrl = this.$route.query.redirectUrl
    this.successGoBack = this.$route.query.successGoBack
    vm = this
    // vm.appId = vm.$route.query.id; //应用ID
    vm.appCode = vm.$route.query.appCode //应用code
    vm.goodsCode = vm.$route.query.goodsCode //应用code
    // 随便查（taxlaw）传来的参数 传递给goods

    vm.getIsLogin()
    // this.getData(this.appId)
  },
  beforeCreate() {
    if (this.$route.query.appCode == 'taxlaw') document.title = '我要购买'
  },
  // watch: {
  //   aaa: function(val) {
  //     window.console.log("watch: " + val);
  //   }
  // },
  mounted() {},
  methods: {
    //登陆
    getIsLogin() {
      getUserInfo().then((res) => {
        if (res.code === 401) {
          // let url = "/api/snslogin/wxgzh?redirect_uri=" + encodeURIComponent(location.href);
          window.console.log(
            '/api/snslogin/wxgzh?redirect_uri=' +
              encodeURIComponent(encodeURIComponent(location.href))
          )
          // window.location.replace(url);
          window.location.href =
            '/api/snslogin/wxgzh?redirect_uri=' +
            encodeURIComponent(encodeURIComponent(location.href))
        }
        this.userInfo = res.data
      })
      // this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 500,
        mask: true,
      })
      this.getData(this.appCode, this.goodsCode) //商品信息
    },
    //返回上一页
    back() {
      this.$router.go(-1)
    },
    //获取商品
    getData(appCode, goodsCode) {
      window.console.log(appCode, goodsCode)
      let that = this
      let data = { code: goodsCode, random: Math.random() } //商品
      goodsDetail(data).then((res) => {
        that.goodsDetail = res.data
        if (that.goodsDetail.goodsSpecificationList == '') {
          that.amount = that.goodsDetail.amount
          that.goodSize = that.goodsDetail.code
        } else {
          for (
            let i = 0;
            i < that.goodsDetail.goodsSpecificationList.length;
            i++
          ) {
            if (
              !(
                that.goodsDetail.isVip != -1 &&
                that.goodsDetail.goodsSpecificationList[i].type == 1
              )
            ) {
              that.amount = that.goodsDetail.goodsSpecificationList[i].amount
              that.goodSize = that.goodsDetail.goodsSpecificationList[i].id
              window.console.log(that.goodSize)
              return
            }
          }
        }
        window.console.log('商品数据：' + that.goodsDetail.name)
        // setTimeout(function() {
        //   that.lb = false;
        // }, 500);
      })
      detail({ code: appCode, random: Math.random() }).then((res) => {
        that.detail = res.data
        window.console.log('商品协议：' + res.data.name)
      }) //协议
    },
    //选择的会员型号
    selectSize(item) {
      window.console.log(item)
      this.goodSize = item.id
      this.amount = item.amount
    },
    //查看协议
    agreeChange() {
      sessionStorage.setItem('name', this.detail.agreementName)
      sessionStorage.setItem('content', this.detail.agreementContent)
      this.$router.push({
        path: '/agreement',
        query: { title: this.detail.name },
      })
      this.showAgree = true
    },
    //获取验证码
    getCode() {
      let that = this
      if (that.phone === '') {
        Toast('请输入手机号')
      } else {
        sendCode({ mobile: that.phone, codeType: 1 }).then((res) => {
          window.console.log(res)
          this.sendAuthCode = false
          this.auth_time = 60
          var auth_timetimer = setInterval(() => {
            this.auth_time--
            if (this.auth_time <= 0) {
              this.sendAuthCode = true
              clearInterval(auth_timetimer)
            }
          }, 1000)
        })
      }
    },
    //支付
    toBay() {
      window.console.log(this.goodSize, this.goodsCode, 'pppppppppp')
      let that = this
      if (that.radio === false) {
        that.isShow = true
        setTimeout(function() {
          that.isShow = false
        }, '1000')
      } else {
        that.isShow = false //未选择协议的弹窗
        window.console.log(that.userInfo.mobile)
        if (that.userInfo.mobile === undefined) {
          that.show = true //未绑定手机号的弹窗
        } else if (that.userInfo.mobile === '') {
          that.show = true //未绑定手机号的弹窗
        } else {
          if (that.goodSize == '') {
            that.sizeShow = true //未选择会员型号
          } else {
            let data = {
              code: that.goodsCode,
              goodsSpecificationId: that.goodSize,
            }
            buy(data).then((res) => {
              that.orderId = res.data.id
              if (res.data.status == 1) {
                this.getData(this.appCode, this.goodsCode) //商品信息
                that.buyShow = true
                setTimeout(function() {
                  that.buyShow = false
                }, '1000')
                if (this.redirectUrl) {
                  window.location.href = that.redirectUrl
                }
              } else {
                if (this.redirectUrl) {
                  window.location.href =
                    '/api/order/pay/' +
                    that.orderId +
                    '?successUrl=' +
                    encodeURIComponent(this.redirectUrl)
                } else if (this.successGoBack) {
                  window.location.href =
                    '/api/order/pay/' +
                    that.orderId +
                    '?successGoBack=' +
                    this.successGoBack
                } else {
                  window.location.href = '/api/order/pay/' + that.orderId
                }
              }
            })
          }
        }
      }
    },
    //跳转购买记录
    toOrder() {
      let that = this
      if (that.userInfo.mobile === undefined) {
        that.show = true //未绑定手机号的弹窗
      } else if (that.userInfo.mobile === '') {
        that.show = true //未绑定手机号的弹窗
      } else {
        var data = {
          appId: this.appId,
          appCode: this.appCode,
        }
        this.$router.push({ path: '/order', query: data })
      }
    },
    //跳转会员激活页面
    toActivation() {
      this.$router.push({ path: '/activation' })
    },
    //账号绑定提交
    dialogSumbit() {
      let that = this
      let obj = {
        mobile: that.phone,
        verifyCode: that.code,
        bindType: 1,
      }
      bindMoibleByCode(obj).then((res) => {
        window.console.log(res)
        Toast(res.msg)
        that.show = false
        that.getIsLogin()
      })
    },
    //返回按钮开关
    openButton() {
      if (this.isOpen) {
        this.x = this.maxX
        this.isOpen = false
      } else {
        this.x = this.maxX - 90
        this.isOpen = true
      }
    },
    //点击返回相关app
    backButton() {
      window.console.log(this.redirectUrl)
      window.location.href = this.redirectUrl
    },
    //图标拖动事件
    moveing(e) {
      e.preventDefault()
      let y = e.changedTouches[0].clientY - 31 //减去高度的一半， 使手指与图标居中
      if (this.minY < y && y < this.maxY) {
        this.y = y
      } else if (y < this.minY) {
        this.y = 0
      } else if (y > this.maxY) {
        this.y = this.maxY
      }
    },
  },
}
</script>

<style scoped>
#app {
  margin-top: -10px;
}
.vipInfo {
  background-color: #3861a3;
  color: #fff;
  position: relative;
  padding: 20px 18px;
}
.vipInfo > img {
  width: 44px;
  height: 36px;
  display: inline-block;
}
.vipInfo > div {
  display: inline-block;
  margin-left: 12px;
}
.vipText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.vipText > p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  line-height: 25px;
  font-size: 12px;
}
.vipText > .vipNickName {
  font-size: 14px;
  line-height: 0px;
}
.vipText > .vipNum {
  font-size: 12px;
  line-height: 7px;
  margin-top: 20px;
}
.other {
  display: block;
  position: absolute;
  top: 8px;
  right: 0px;
  width: 67px;
  height: 21px;
  background-color: #fff;
  border-radius: 4px 0 0 4px;
  color: #666;
  font-size: 13px;
  margin: 0px;
  text-align: center;
  line-height: 23px;
}
.unitPrice {
  position: absolute;
  right: 15px;
  bottom: 18px;
  font-size: 10px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.page {
  width: 100%;
  background-color: #f6f7f9;
  padding-bottom: 30px;
}

.top {
  background-color: white;
  color: #222222;
  text-align: center;
  font-size: 24px;
  padding: 10px 20px 10px 20px;
}

.back {
  float: left;
}

.record {
  text-align: right;
  font-size: 12px;
  color: #222222;
  width: 100%;
  background-color: #f8fbfe;
  line-height: 25px;
}

.spacing {
  font-size: 13px;
  padding: 9px 10px 9px 0;
  font-family: SourceHanSansCN-Regular;
}

.content {
  width: calc(100% - 25px);
  background: #fff;
  border-radius: 8px;
  margin: 0px 12.5px;
  overflow: hidden;
}
.describe >>> img {
  width: 100%;
}
.describe >>> p {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

/* .bottom {
  position: fixed;
  bottom: 0px;
  color: #000;
  background-color: #fff;
  line-height: 30px;
  width: calc(100% - 60px);
  padding: 15px 30px 15px 30px;
  line-height: 27px;
}
.bottom > .amount {
  color: #ec3232 !important;
  font-size: 15px;
  vertical-align: bottom;
} */
.pay {
  font-size: 14px;
  color: #ffffff;
  width: 100px;
  height: 32px;
  text-align: center;
  display: block;
  background-color: #3861a3;
  border-radius: 16px;
  line-height: 32px;
  margin: 0px auto;
}
/* .activation {
  height: 27px;
  line-height: 27px;
  border-radius: 27px;
  display: inline-block;
  font-size: 13px;
  width: 85px;
  color: #ffffff;
  background-color: #bcbcbc;
  text-align: center;
  margin-right: 10px;
  float: right;
} */

/* 会员套餐 */
.memberPackage {
  background-color: #fff;
  border-radius: 8px;
  margin: 12.5px;
  margin-bottom: 6px;
  padding-bottom: 24px;
  overflow: hidden;
}
.memberPackage > ul {
  padding: 24px 15px 0px 15px;
}
.memberPackage li {
  margin-bottom: 32px;
  width: 100%;
  height: 50px;
  background: url(../../assets/box.png) no-repeat;
  background-size: contain;
  position: relative;
}
@media screen and (min-width: 320px) {
  .memberPackage li {
    height: 50px;
  }
  .memberPackage li > .info {
    padding-left: 55px;
    font-size: 12px;
    position: absolute;
    top: 12px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .memberPackage li {
    height: 50px;
  }
  .memberPackage li > .info {
    padding-left: 73.5px;
    font-size: 15px;
    position: absolute;
    top: 15px;
  }
  .memberPackage li > .info > .price {
    margin: 0 5px;
  }
}
@media screen and (min-width: 376px) and (max-width: 410px) {
  .memberPackage li {
    height: 50px;
  }
  .memberPackage li > .info {
    padding-left: 73.5px;
    font-size: 15px;
    position: absolute;
    top: 15px;
  }
  .memberPackage li > .info > .price {
    margin: 0 5px;
  }
}
@media screen and (min-width: 411px) and (max-width: 480px) {
  .memberPackage li {
    height: 53px;
  }
  .memberPackage li > .info {
    padding-left: 73.5px;
    font-size: 15px;
    position: absolute;
    top: 18px;
  }
  .memberPackage li > .info > .price {
    margin: 0 5px;
  }
}
.memberPackage li > .info {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.memberPackage li > .info > p {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.memberPackage li > .info > .sizetype {
  color: #7c4813;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.memberPackage li > .info > .price {
  color: #f76a07;
  min-width: 50px;
  max-width: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.memberPackage li > .info > .text {
  color: #7c4813;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.memberPackage li:last-child {
  margin-bottom: 0px;
}
.memberPackage li.new {
  background: url(../../assets/boxselect.png) no-repeat;
  background-size: contain;
}

.memberPackage li.new .info > .text {
  color: #7c4813;
}
.line {
  /*width: 90%;*/
  padding: 20px 0 10px 0;
  margin: 0 25px 0 25px;
  border-bottom: 1px dashed #f2f2f2;
  height: auto !important;
  min-height: 20px;
}

.left {
  float: left;
  width: 25px;
}

.right {
  /*float: right;*/
  /*width: 90%;*/
  font-size: 12px;
  color: #222222;
  word-wrap: break-word;
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
img {
  width: 100%;
}
.agreement {
  color: #298afd;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
}

.agreement >>> .van-checkbox__icon {
  color: #298afd;
  width: 10px !important;
  height: 10px !important;
  display: inline-block;
  margin-right: 5px;
}
.dialog {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
.tips {
  background-color: #999999;
  color: #ffffff;
  font-size: 12px;
  width: 165px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
}
.agree {
  color: white;
  font-size: 12px;
  background-color: #999999;
  border-radius: 10px;
  width: 80%;
  position: absolute;
  padding-top: 10px;
  left: 10%;
  bottom: 44%;
}

.off {
  width: 50px;
  line-height: 30px;
  background-color: white;
  color: #000000;
  font-size: 12px;
  text-align: center;
  margin: 10px auto;
  border-radius: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  height: 450px;
  background-color: #fff;
  border-radius: 10px;
}

.block >>> p {
  height: 370px;
  overflow-y: auto;
  text-align: justify;
}

.block1 {
  width: 80%;
  height: 250px;
  background-color: #fff;
  border-radius: 10px;
}

.offSumbit {
  margin: 5px auto;
  line-height: 30px;
  background-color: #378bf4;
  color: white;
  width: 70px;
  font-size: 12px;
  text-align: center;
  border-radius: 10px;
}

.dialogTop {
  width: 100%;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #378bf4;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.dialogPhone {
  margin: 25px 25px 25px 25px;
  width: 80%;
  height: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f5fa;
}

.dialogPhone >>> .van-cell {
  width: 70%;
  float: left;
  padding: 0;
  font-size: 11px;
  color: #666666;
}

.dialogCode {
  margin: 25px 25px 25px 25px;
  width: 80%;
  height: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f5fa;
}

.dialogCode >>> .van-cell {
  width: 50%;
  float: left;
  padding: 0;
  font-size: 11px;
  color: #666666;
}

.dialogSumbit {
  width: 80%;
  font-size: 12px;
  color: #ffffff;
  line-height: 30px;
  background-color: #378bf4;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
}

.van-cell:not(:last-child)::after {
  border-bottom: 0;
}
.backButton {
  position: fixed;
  width: 150px;
  height: 62px;
  background: url('../../assets/return.png');
  background-size: contain;
  z-index: 999;
  transition: left 1.5s;
}
.backButton > img {
  position: relative;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}
</style>
